import React, {Fragment, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import {Controller, useFormContext} from 'react-hook-form/dist/index.ie11';

import Input from 'patient-ping-remedy/packages/input';
import DropdownList from 'patient-ping-remedy/packages/dropdown-list';
import DatePicker from 'patient-ping-remedy/packages/date-picker';
import {sizes} from 'patient-ping-remedy/packages/theme';
import Spacer from 'patient-ping-remedy/packages/spacer';
import Genders from "../../constants/Genders";
import {StyledGenderContainer, StyledRow, StyledSSNInput} from "../../encounter_client_search/PatientInformationForm.styles";
import DisplayHelpers from "../../../../../../helpers/display_helpers";

const { MALE, FEMALE, NON_BINARY, OTHER } = Genders;
const genderOptions = [MALE, FEMALE, NON_BINARY, OTHER].map((gender) => ({ value: gender, label: gender }));

const requiredField = 'Required field';
const invalidValue = 'Invalid value';
const validPhoneTypes = ['mobile', 'home', 'other'];

const PatientDemographics = ({
  disabled = false,
  showAll = true,
  isWithinModal = true,
  focusOnLoad = true,
  featureName = 'PatientAdmit',
}) => {
  const firstNameRef = useRef();
  const { register, control, errors, watch, formState } = useFormContext();
  const { dirtyFields } = formState;

  useEffect(() => {
    if (focusOnLoad) {
      firstNameRef.current.focus();
    }
  }, []);

  const { ssn, zip, phone, address1, address2, city, state } =
    watch(['ssn', 'zip', 'phone', 'phoneType', 'address1', 'address2', 'city', 'state']);

  const isSSNDisabled = (disabled && ssn && !dirtyFields.ssn);
  const isZipDisabled = (disabled && zip && !dirtyFields.zip);
  const isPhoneDisabled = (disabled && phone && !dirtyFields.phoneNumber);
  const isAddress1Disabled = (disabled && address1 && !dirtyFields.address1);
  const isAddress2Disabled = (disabled && address2 && !dirtyFields.address2);
  const isStateDisabled = (disabled && state && !dirtyFields.state);
  const isCityDisabled = (disabled && city && !dirtyFields.city);

  return (
    <Fragment>
      <StyledRow>
        <Input
          name="firstName"
          labelText="First Name"
          id="firstName"
          error={errors?.firstName}
          errorMessage={requiredField}
          required="required"
          isDisabled={disabled}
          ref={(input) => {
            register(input, {
              required: showAll ? requiredField : false,
              maxLength: 50,
              minLength: showAll ? 2 : 0,
              pattern: /^[A-Za-z]/,
            });
            firstNameRef.current = input;
          }}
        />
        <Spacer itemWidth={sizes.large} />
        <Input
          name="lastName"
          labelText="Last Name"
          id="lastName"
          error={errors?.lastName}
          errorMessage={requiredField}
          required="required"
          isDisabled={disabled}
          ref={register({
            required: showAll ? requiredField : false,
            maxLength: 50,
            minLength: showAll ? 2 : 0,
            pattern: /^[A-Za-z]/,
          })}
        />
      </StyledRow>

      {showAll && (
        <Fragment>
          <StyledRow>
            <StyledGenderContainer>
              <Controller
                name="gender"
                control={control}
                rules={{required: true}}
                render={({onChange, value, onBlur}) => (
                  <DropdownList
                    handleChange={({value: newValue}) => onChange(newValue)}
                    onBlur={onBlur}
                    value={genderOptions.find((g) => g.value === value)}
                    id="gender"
                    items={genderOptions}
                    label="Gender"
                    disabled={disabled}
                    isWithinModal={isWithinModal}
                    required
                    error={errors?.gender}
                    errorMessage={requiredField}
                  />
                )}
              />
            </StyledGenderContainer>
            <Controller
              name="dob"
              control={control}
              rules={{required: true}}
              render={({onChange, onBlur, value}) => (
                <DatePicker
                  value={value}
                  onChange={onChange}
                  disabled={disabled}
                  maxDate={new Date()}
                  externalError={errors?.dob}
                  externalErrorText={requiredField}
                  id={`dob${featureName}`}
                  onBlur={onBlur}
                  labelText="Date of Birth"
                  required="required"
                />
              )}
            />
          </StyledRow>
          <StyledRow>
            <Controller
              name="phoneType"
              control={control}
              required="optional"
              render={({onChange, value, onBlur}) => (
                <DropdownList
                  handleChange={({value: newValue}) => onChange(newValue)}
                  onBlur={onBlur}
                  value={validPhoneTypes.find((type) => type === value)
                    ? {value: value, label: value.charAt(0).toUpperCase() + value.slice(1)}
                    : null}
                  id="phoneType"
                  items={[
                    {value: 'mobile', label: 'Mobile'},
                    {value: 'home', label: 'Home'},
                    {value: 'other', label: 'Other'},
                  ]}
                  label="Phone Type"
                  labelMessage="optional"
                  required={false}
                />
              )}
            />
            <Spacer itemWidth={sizes.large}/>
            <Input
              name="phone"
              id="phone"
              type="text"
              labelText="Phone Number"
              required="optional"
              value={DisplayHelpers.formatPhoneNumber(phone)}
              placeholder="(___) ___-____"
              isDisabled={isPhoneDisabled}
              error={errors?.phone}
              errorMessage={errors?.phone && 'Phone number must be in the format (XXX) XXX-XXXX.'}
              ref={register({
                required: false,
                pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
                trim: true,
              })}
            />
          </StyledRow>
          <Input
            name="address1"
            id="address1"
            type="text"
            labelText="Address 1"
            required="optional"
            isDisabled={isAddress1Disabled}
            error={errors.address1}
            errorMessage={errors.address1 && invalidValue}
            maxLength="100"
            ref={register({
              required: false,
              maxLength: 100,
              trim: true,
            })}
          />
          <Spacer itemWidth={sizes.large}/>
          <Input
            name="address2"
            id="address2"
            type="text"
            labelText="Address 2"
            required="optional"
            isDisabled={isAddress2Disabled}
            error={errors.address2}
            errorMessage={errors.address2 && invalidValue}
            maxLength="100"
            ref={register({
              required: false,
              maxLength: 100,
              trim: true,
            })}
          />
          <StyledRow>
            <Input
              name="city"
              id="city"
              type="text"
              labelText="City"
              required="optional"
              isDisabled={isCityDisabled}
              error={errors.city}
              errorMessage={errors.city && invalidValue}
              maxLength="50"
              ref={register({
                required: false,
                maxLength: 50,
                trim: true,
              })}
            />
            <Input
              name="state"
              id="state"
              type="text"
              labelText="State"
              required="optional"
              isDisabled={isStateDisabled}
              error={errors.state}
              errorMessage={errors.state && invalidValue}
              maxLength="2"
              ref={register({
                required: false,
                maxLength: 2,
                pattern: /^[A-Za-z]{2}$/,
              })}
            />
          </StyledRow>
          <StyledRow>
            <Input
              name="zip"
              id="zip"
              type="text"
              labelText="Zip Code"
              required="optional"
              isDisabled={isZipDisabled}
              error={errors.zip}
              errorMessage={errors.zip && invalidValue}
              maxLength="5"
              ref={register({
                required: false,
                pattern: /[0-9]{5}/,
              })}
            />
            <div>
              {/* UE-3050 Only validate if field is enabled */}
              <StyledSSNInput
                name="ssn"
                id="ssn"
                type="text"
                labelText="SSN - Last Four"
                required="optional"
                isDisabled={isSSNDisabled}
                error={errors?.ssn}
                errorMessage={errors.ssn && invalidValue}
                placeholder="####"
                maxLength="4"
                ref={register(
                  isSSNDisabled
                    ? {required: false}
                    : {
                      required: false,
                      pattern: /(?!0000)[0-9]{4}$/,
                      maxLength: 4,
                    },
                )}
              />
            </div>
          </StyledRow>
        </Fragment>
      )}
    </Fragment>
  );
};

PatientDemographics.propTypes = {
  disabled: PropTypes.bool,
  showAll: PropTypes.bool,
  isWithinModal: PropTypes.bool,
  focusOnLoad: PropTypes.bool,
  featureName: PropTypes.string,
};

export default PatientDemographics;
