import React, {useContext} from 'react';
import DropdownList from "patient-ping-remedy/packages/dropdown-list";
import { Value } from 'patient-ping-remedy/packages/dropdown-list/lib/DropdownList';
import { useRosterStore } from '../../store/roster_store';
import {css} from "@emotion/css";
import {CarecoApiContext} from "../../app-context/careco-api-context";
import {useQuery} from "@tanstack/react-query";
import {router} from "../../router";
import RosterHelpers from "../../helpers/roster_helpers";
import {FacilityPickerWrapper} from "./FacilityPicker.styles";

const FacilityPicker = () => {
  const { currentRoster, setCurrentRoster } = useRosterStore();
  const { carecoApi } = useContext(CarecoApiContext);

  const { data: rosters } = useQuery({
    queryKey: ['rosters'],
    queryFn: () => carecoApi?.getRosters(),
    enabled: !!carecoApi
  });

  const dropDownItems = rosters?.rosterOptions ?? [];

  const handleChange = (val : Value | null) => {
    const roster = RosterHelpers.getRosterById(rosters, val?.value);
    setCurrentRoster(roster);

    if(window.location.pathname.includes('client_profile')) {
      router.navigate('/clients');
    }
  };

  return (
    <FacilityPickerWrapper>
      <DropdownList
        className={css({ height: '40px', width: '250px', zIndex: '2' })}
        items={dropDownItems}
        value={{
          label: currentRoster?.name ? currentRoster.name : 'Select a group',
          value: currentRoster?.id ? currentRoster.id.toString() : ''
        }}
        handleChange={handleChange}
        id={'facility-picker'}
      />
    </FacilityPickerWrapper>
  );
};

export default FacilityPicker;
