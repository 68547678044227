import {useInfiniteQuery} from "@tanstack/react-query";
import {useCallback, useContext, useEffect, useState} from 'react';
import {CarecoApiContext} from "../app-context/careco-api-context";
import {NotificationAlert, NotificationDto} from "../api/dto/notification";
import {useNotificationStore} from "../store/notification_store";
import {useAlertStore} from "../store/alert_store";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {GENERICERROR} from "../components/pages/clients/manual_encounter/constants/BannerMessages";
import Helpers from "../helpers/helpers";


type UseNotificationsProps = {
  currentRosterId: number;
  onlyShowUnreadNotifications: boolean;
  refetchInterval?: number;
  enabled?: boolean;
  limit?: number;
};

export const useNotifications = ({
  currentRosterId, 
  onlyShowUnreadNotifications,
  refetchInterval = 30000,
  enabled = true,
}: UseNotificationsProps) => {
  const {
    notifications,
    setNotifications,
    updateNotificationViewed,
    removeNotification,
    totalUnreadNotifications,
    setTotalUnreadNotifications,
  } = useNotificationStore();
  const [ totalElements, setTotalElements ] = useState<number>(0);
  const { carecoApi } = useContext(CarecoApiContext);
  const { addAlert } = useAlertStore();

  const result = useInfiniteQuery({
    queryKey: ['notifications', currentRosterId, onlyShowUnreadNotifications],
    queryFn: ({ pageParam }) => {
      return carecoApi?.getNotifications(
        currentRosterId, onlyShowUnreadNotifications, pageParam,
      );
    },
    enabled: enabled && !!carecoApi,
    getNextPageParam: (lastPage) => lastPage?.nextPage,
    initialPageParam: 1,
    refetchInterval,
  });

  const { data: notificationsData, isLoading, fetchNextPage, isFetching, error } = result;

  const getNotificationsData = useCallback((notificationsData: any) => {
    return notificationsData.pages.flatMap(
      (page: { data: { notifications: NotificationDto[]; }; }) =>
        page.data.notifications);
  }, []);

  const getTotalElements = useCallback((notificationsData: any) => {
    return notificationsData.pages[0].data.totalElements;
  }, []);

  const setNotificationViewed = (id: number, viewed: boolean) => {
    carecoApi?.putNotificationViewed(id, currentRosterId, viewed)
      .then(() => {
        let alertContent;

        if (viewed) {
          alertContent = NotificationAlert.NOTIFICATION_MARKED_AS_READ;
          setTotalUnreadNotifications(totalUnreadNotifications - 1);
        } else {
          alertContent = NotificationAlert.NOTIFICATION_MARKED_AS_UNREAD;
          setTotalUnreadNotifications(totalUnreadNotifications + 1);
        }

        addAlert({content: alertContent, type: ToastType.SUCCESS});
        if (onlyShowUnreadNotifications) {
          removeNotification(id);
        } else {
          updateNotificationViewed(id, viewed);
        }
      })
      .catch(error => {
        const traceId = error.response?.headers['x-trace-id'];
        addAlert({
          content: `${NotificationAlert.FAILED_TO_MARK_NOTIFICATION} ${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        console.error("Error updating notification viewed: " + error);
      });
  };

  const markAllAsRead = () => {
    carecoApi?.putAllNotificationsAsViewed(currentRosterId)
      .then(() => {
        addAlert({content: NotificationAlert.ALL_NOTIFICATIONS_MARKED_AS_READ, type: ToastType.SUCCESS});
        setTotalUnreadNotifications(0);
        if (onlyShowUnreadNotifications) {
          setNotifications([]);
        } else {
          let updatedNotifications = notifications.map(notification =>
            ({...notification, viewed: true})
          );
          setNotifications(updatedNotifications);
        }
      })
      .catch(error => {
        const traceId = error.response?.headers['x-trace-id'];
        addAlert({
          content: `${NotificationAlert.FAILED_TO_MARK_ALL_NOTIFICATIONS} ${GENERICERROR} ${Helpers.traceId(traceId)}`,
          type: ToastType.ERROR
        });
        console.error("Error updating all notifications viewed: " + error);
      });
  };

  useEffect(() => {
    if (!currentRosterId) {
      setNotifications([]);
      setTotalUnreadNotifications(0);
    }
  }, [currentRosterId]);

  useEffect(() => {
    if (notificationsData) {
      setNotifications(getNotificationsData(notificationsData));
    }
  }, [notificationsData, getNotificationsData]);

  useEffect(() => {
    if (notificationsData) {
      setTotalElements(getTotalElements(notificationsData));
      if (onlyShowUnreadNotifications) {
        setTotalUnreadNotifications(getTotalElements(notificationsData));
      }
    }
  }, [notificationsData, getNotificationsData, getTotalElements]);

  return {
    isLoading,
    fetchNextPage,
    totalElements,
    totalUnreadNotifications,
    isFetching,
    error,
    getTotalElements,
    setNotificationViewed,
    markAllAsRead,
  };
};
