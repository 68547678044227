import AuthGuard from "./components/AuthGuard";
import ErrorPage from "./components/pages/error_pages/ErrorPage";
import ProfilePage from "./components/pages/profile/ProfilePage";
import React from "react";
import {RouteObject} from "react-router-dom";
import AppSettingsPage from "./components/pages/AppSettingsPage";
import AdminPage from "./components/pages/admin/AdminPage";
import ClientsPage from "./components/pages/clients/ClientsPage";
import ClientProfilePage from "./components/pages/clients/profile_page/ClientProfilePage";
import Unauthorized from "./components/pages/error_pages/Unauthorized";
import ConsentPage from "./components/pages/ConsentPage";
import FailedAttestation from "./components/pages/error_pages/FailedAttestation";
import Helpers from "./helpers/helpers";
import FailedPhoneVerification from "components/pages/error_pages/FailedPhoneVerification";
import NotificationsPage from "./components/pages/notifications/NotificationsPage";
import Logout from "./components/Logout";
import PasswordExpired from "./components/pages/error_pages/PasswordExpired";
import ToolsPage from "./components/pages/tools/ToolsPage";
import AnalyticsPage from "./components/pages/analytics/AnalyticsPage";

const routes: RouteObject[] = [
  {
    path: '/',
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/login',
        element: <ConsentPage login />
      },
      {
        path: '/logout',
        element: <Logout />
      },
      {
        path: '/attestation',
        element: <ConsentPage />
      },
      {
        path: '/complete-attestation',
        element: <FailedAttestation />
      },
      {
        path: '/failed-phone-verification',
        element: <FailedPhoneVerification />
      },
      {
        path: '/unauthorized',
        element: <Unauthorized />
      },
      {
        path: '/password-expired',
        element: <PasswordExpired />
      },
      {
        index: true,
        element: <AuthGuard Component={ClientsPage} />
      },
      {
        path: '/clients',
        element: <AuthGuard Component={ClientsPage} />
      },
      {
        path: '/profile',
        element: <AuthGuard Component={ProfilePage} />
      },
      {
        path: '/admin',
        element: <AuthGuard featurePermissionsCheck={{
          level: Helpers.getAppFeatureRoleDetails().levels.VIEW,
          feature: Helpers.getAppFeatureRoleDetails().permissions.USER_PERMISSIONS
        }} Component={AdminPage} />
      },
      {
        path: '/app_settings',
        element: <AuthGuard role={Helpers.getAppRoles().BAMBOO_ADMIN} Component={AppSettingsPage} />
      },
      {
        path: '/tools',
        element: <AuthGuard role={Helpers.getAppRoles().BAMBOO_ADMIN} Component={ToolsPage} />
      },
      {
        path: '/client_profile',
        element: <AuthGuard featurePermissionsCheck={{
          level: Helpers.getAppFeatureRoleDetails().levels.VIEW,
          feature: Helpers.getAppFeatureRoleDetails().permissions.CLIENT_PROFILE
        }} Component={ClientProfilePage} />
      },
      {
        path: '/notifications',
        element: <AuthGuard featurePermissionsCheck={{
          level: Helpers.getAppFeatureRoleDetails().levels.VIEW,
          feature: Helpers.getAppFeatureRoleDetails().permissions.NOTIFICATIONS
        }} Component={NotificationsPage} />
      },
      {
        path:'/analytics',
        element: <AuthGuard Component={AnalyticsPage}/>
      },
    ],
  },
];

export default routes;
