import React from 'react';
import {router} from '../../router';
import {Button} from './Logo.styles';

export default function Logo() {
  return (
    <Button onClick={() => router.navigate('/clients')}>
      <img src="/bamboo-health-logo.svg" alt="DTRN360 Powered by Bamboo Health" />
    </Button>
  );
}
