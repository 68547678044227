import React, {PropsWithChildren, useEffect} from "react";
import GlobalNavBar from "./header/GlobalNavBar";
import NavRail from "./navigation/NavRail";
import {Wrapper, Header, LeftAside, Main} from "./Layout.styles";
import {useRosterStore} from "../store/roster_store";
import {useAlertStore} from "../store/alert_store";
import ToastNotification from "patient-ping-remedy/packages/toast";
import {useLocation} from "react-router-dom";

export default function Layout(props: PropsWithChildren) {
  const { currentRoster, setCurrentRoster, selectedRoster, setSelectedRoster } = useRosterStore();
  const { alerts, setAlerts } = useAlertStore();
  const location = useLocation();

  const isFacilityContext = location.pathname !== '/admin';

  useEffect(() => {
    if (!isFacilityContext) {
      setCurrentRoster(null);
      return;
    }

    if (currentRoster) {
      setSelectedRoster(currentRoster);
    } else {
      setCurrentRoster(selectedRoster);
    }
  }, [isFacilityContext, currentRoster, selectedRoster]);

  return (
    <Wrapper>
      <Header>
        <GlobalNavBar isFacilityContext={isFacilityContext} />
      </Header>
      <LeftAside>
        <NavRail />
      </LeftAside>
      <Main>
        {props.children}
      </Main>
      <ToastNotification
        toasts={[...alerts]}
        remove={(index) => setAlerts(alerts.filter((_, i) => i !== index))}
      />
    </Wrapper>
  );
}
