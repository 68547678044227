import styled from '@emotion/styled';

export const Button = styled.button`
  height: 34px;
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;

  & > img {
    height: 100%;
  }
`;
