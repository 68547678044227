import React, {Fragment, useContext, useEffect, useMemo} from 'react';
import {FormProvider, useForm} from 'react-hook-form/dist/index.ie11';

import Modal from 'patient-ping-remedy/packages/modal';
import {BannerTypes} from 'patient-ping-remedy/packages/banner';
import Button, {StyleType} from 'patient-ping-remedy/packages/button';
import Spacer from 'patient-ping-remedy/packages/spacer';
import {sizes} from 'patient-ping-remedy/packages/theme';

import PatientDemographics from './PatientDemographicsHookForm';
import {moment} from "vis-timeline/standalone";
import AdmitHookForm from "./AdmitHookForm";
import {CarecoApiContext} from "../../../../../../app-context/careco-api-context";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {getGenderAbbreviation} from "../../constants/Genders";
import {StyledSmallTypography} from "../../encounter_client_search/AdmitClientModal.styles";
import {useAlertStore} from "../../../../../../store/alert_store";
import Helpers from "../../../../../../helpers/helpers";
import {ToastType} from "patient-ping-remedy/packages/toast";
import {formatSubmitInsurancePlans, getInsurancePlanOptions} from "../../encounter_update_status/pings/utils/utils";
import {GENERICERROR, SUCCESS_ADMIT} from "../../constants/BannerMessages";
import InsurancePlanHookForm from "../../encounter_update_status/pings/components/InsurancePlansHookForm";
import {GroupType} from "../../../../../../api/dto/dto";
import DisplayHelpers from "../../../../../../helpers/display_helpers";

const newPatientBanner = {
  type: BannerTypes.INFORMATIONAL,
  text: 'You have no client records similar to the client you are admitting. Please create a new client record.',
};

export const getDefaultValues = (patient, admittingGroup) => {
  const { currentInsurancePlan, patientInsurancePlans } = getInsurancePlanOptions({
    ...patient, currentPing: {
      manual: true,
    }});

  let dob = patient.dateOfBirth;
  if (dob) {
    const momentDOB = moment.utc(patient.dateOfBirth);
    dob = moment(momentDOB.format('YYYY-MM-DD')).toDate();
  }

  return {
    firstName: patient.firstName || '',
    lastName: patient.lastName || '',
    insurancePlans: patientInsurancePlans,
    gender: patient.gender,
    dob,
    zip: patient.address?.zip,
    ssn: patient.ssn,
    admittedFrom: { label: patient.currentPingEncounter?.admittedFromLocation || '', id: '' },
    selectedInsurancePlan: currentInsurancePlan,
    groupId: admittingGroup?.id,
    date: new Date(),
    setting: '',
    phone: DisplayHelpers.formatPhoneNumber(patient.phone),
    phoneType: patient.phoneType,
    address1: patient.address?.address1,
    address2: patient.address?.address2,
    city: patient.address?.city,
    state: patient.address?.state,
  };
};

const CreateEditClientModal = ({ hideModal, patient, admittingGroup }) => {
  const isCreating = patient.patientId === 'NEW';
  const defaultValues = useMemo(() => getDefaultValues(patient, admittingGroup), [patient, admittingGroup]);
  const hookFormMethods = useForm({ defaultValues, mode: 'onTouched' });
  const { formState: { errors } } = hookFormMethods;
  const { addAlert } = useAlertStore();
  const queryClient = useQueryClient();

  const {encounterApi} = useContext(CarecoApiContext);

  const {mutateAsync : updatePatientMutation, status: updatePatientStatus} = useMutation({
    mutationFn:(data) => encounterApi?.updatePatient( data, patient.publicPatientId, admittingGroup.id),
    mutationKey: ['updatePatient'],
    onError: (error) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: `Failed to update client. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    }
  });

  const {mutateAsync : createPatientMutation, status: createPatientStatus} = useMutation({
    mutationFn:(data) => encounterApi?.createPatient( admittingGroup.id, data),
    mutationKey:['createPatient'],
    onError: (error) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: `Failed to create client. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    }
  });

  const {mutateAsync : admitPatientMutation, status: admitPatientStatus} = useMutation({
    mutationFn: (data) => encounterApi?.createEncounter(data, data.publicPatientId, admittingGroup.id),
    mutationKey: ['admitPatient'],
    onSuccess: () => {
      addAlert({content: SUCCESS_ADMIT, type: ToastType.SUCCESS});
      queryClient.invalidateQueries({queryKey: ["clients", admittingGroup.id]});
    },
    onError: (error) => {
      const traceId = error.response?.headers['x-trace-id'];
      addAlert({
        content: `Failed to admit client. ${GENERICERROR} ${Helpers.traceId(traceId)}`,
        type: ToastType.ERROR
      });
      console.error(error);
    }
  });

  useEffect(() => {
    const errorsArray = Object.values(errors);
    if (errorsArray.length > 0) {
      const errorName = errorsArray[0]?.ref?.name;
      const errorNode = document.querySelector(`#${errorName}`);
      errorNode?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [errors]);

  const isSnf = useMemo(() => {
    return admittingGroup?.type?.trim().toUpperCase() === GroupType.SNF;
  }, [admittingGroup]);

  function fetchSelectedInsurance(insurancePlans, currentPayor) {
    if(Helpers.isDefined(currentPayor)) return currentPayor;
    if(insurancePlans.length > 0) {
      const selectedPlan = insurancePlans.find(plan => plan.selected);
      return Helpers.isDefined(selectedPlan) ? selectedPlan : insurancePlans[0];
    }
  }

  async function submitCreating(patientData){
    const { firstName, lastName, gender, dob, ssn, address1, address2, city,
      state, zip, phone, phoneType, insurancePlans } = patientData;

    const formattedInsurancePlans = formatSubmitInsurancePlans(
      defaultValues.insurancePlans,
      insurancePlans,
      patientData.selectedInsurancePlan,
      isCreating,
    );

    const formattedPhoneNumber =  phone?.replace(/\D/g, '').substring(0, 10);

    const formattedData = {
      firstName,
      lastName,
      gender : getGenderAbbreviation(gender),
      dateOfBirth : moment(dob).format('YYYY-MM-DD'),
      address: {
        address1,
        address2,
        city,
        state,
        zip,
      },
      phone: formattedPhoneNumber,
      phoneType,
      insurancePlans: formattedInsurancePlans,
      ssn: Helpers.isEmpty(ssn || "") ? null : ssn,
    };

    if (isCreating) {
      createPatientMutation(formattedData).then(({data}) => {
        let selectedInsurancePlan = fetchSelectedInsurance(data.insurancePlans, data.currentPayor);
        const updates = { ...patientData, selectedInsurancePlan};
        handleAdmit(updates, data.publicPatientId);
      });
      return;
    }
    const updates = {
      ...formattedData,
      publicPatientId: patient.publicPatientId,
      firstName : patient.firstName,
      lastName : patient.lastName,
    };
    updatePatientMutation(updates).then(({data}) => {
      const patientDataUpdates = {
        ...patientData,
        selectedInsurancePlan: {
          id: data.currentInsurancePlans?.find(plan => plan.selected)?.id,
        }
      };
      handleAdmit(patientDataUpdates, updates.publicPatientId);
    });
  }

  const handleAdmit = (update, patientId) => {
    const admitUpdate = {
      publicPatientId: patientId,
      creatorGroupId: admittingGroup.id,
      admit: {
        date: update.date,
        admittedFromFacilityId: update.admittedFrom?.id,
        admittedFromFacilityName: update.admittedFrom?.label,
        setting: update.setting,
        selectedInsurancePlan: {
          id: parseInt(update.selectedInsurancePlan?.id),
        },
      },
    };
    admitPatientMutation(admitUpdate).then( () =>{
      hideModal();
      const currentPage = window.location.href;
      if((currentPage.includes("/client_profile") && currentPage.includes(patientId))
        || currentPage.includes("/notifications")){
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }
    });
  };

  const modalControls = {
    headerText: `Admit Client - ${admittingGroup.name}`,
    primaryButton: { action: submitCreating, text: 'Admit Client' },
  };

  const isDisabled = modalControls.primaryButton.disabled ||
    [createPatientStatus, updatePatientStatus, admitPatientStatus].includes('pending');
  const banner = isCreating && newPatientBanner;

  const renderEditCreatePatient = () => {
    return (
      <Fragment>
        <PatientDemographics disabled={!isCreating} />
        <InsurancePlanHookForm
          defaultSelectedPlan={defaultValues.selectedInsurancePlan}
        />
        <Spacer itemHeight={sizes.medium} />
        <AdmitHookForm
          isEditing={true}
          patient={patient}
          isPcc={false}
          isSnf={isSnf}
          groupId={admittingGroup?.id}
        />
      </Fragment>
    );
  };

  const modalButtons = [
    <Button
      key="submit"
      styleType={StyleType.PRIMARY}
      disabled={isDisabled}
      onClick={hookFormMethods.handleSubmit(modalControls.primaryButton.action)}
    >
      {modalControls.primaryButton.text}
    </Button>,
    <Button key="cancel" styleType={StyleType.TERTIARY} onClick={hideModal}>
      Cancel
    </Button>,
  ];

  return (
    <Modal banner={banner} headerText={modalControls.headerText} buttons={modalButtons}>
      <StyledSmallTypography>
        All fields marked with <span>*</span> are required.
      </StyledSmallTypography>
      <FormProvider {...hookFormMethods}>{renderEditCreatePatient()}</FormProvider>
    </Modal>
  );
};

export default CreateEditClientModal;
