import {CSScoreDiv} from "./PdmpScoreTile.styles";
import {StandardTypography} from "patient-ping-remedy/packages/typography";
import React from "react";
import {AxiosError, HttpStatusCode} from "axios";
import {LinkAccount} from "./PdmpError.styles";

type Props = {
  error: Nullable<Error>;
  isTile: boolean
};

const userNotSetUpResponse = 'You are currently not set up to receive PMP data.';

const PdmpError = (props: Props) => {
  const ErrorDiv = () => {
    if(props.error instanceof AxiosError && props.error?.response?.status === HttpStatusCode.Forbidden) {
      let userNotSetUp = props.error?.response?.data === userNotSetUpResponse;
      return (
        <StandardTypography>
          {props.error?.response?.data as string}<br/>
          {userNotSetUp && <LinkAccount justify={props.isTile ? 'flex-end' : 'start'}
            to={'/profile'}>Link your account here</LinkAccount>}
        </StandardTypography>
      );
    } else {
      return (
        <StandardTypography>
          Failed to load PMP data. Please try again and contact your system administrator if the issue persists.
        </StandardTypography>
      );
    }
  };

  if(props.isTile) return <CSScoreDiv><ErrorDiv /></CSScoreDiv>;

  return <ErrorDiv />;
};

export default PdmpError;
