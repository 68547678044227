import {
  CSScoreDiv,
  ORSDetails,
  ORSGraph,
  ORSLabel,
  ORSMainDiv,
  ORSScore,
  ORSScores,
  ORSTileHeading,
  ORSValue,
  StyledSmallTypography,
  StyledTooltipHeader
} from "./PdmpScoreTile.styles";
import {PatientResponse} from "../../../../../api/dto/pdmp";
import React, {useLayoutEffect, useMemo, useRef} from "react";
import Loading from "../../../../common/Loading";
import Button, {StyleType} from "patient-ping-remedy/packages/button";
import ToolTip from "patient-ping-remedy/packages/tool-tip";
import Helpers from "../../../../../helpers/helpers";
import PdmpScoreGraph from "./PdmpScoreGraph";
import PdmpError from "./PdmpError";
import IconButton from "patient-ping-remedy/packages/icon-button";
import {ICONS} from "patient-ping-remedy/packages/icon";

export type PdmpProps = {
  pdmpPatientResponse: PatientResponse | undefined;
  error: Nullable<Error>;
  isLoading: boolean;
  tabsRef: React.RefObject<any>;
  pdmpTabIndex: number;
}

const ScoreTypes = {
  NARCOTICS: 'Narcotics',
  SEDATIVES: 'Sedatives',
  STIMULANTS: 'Stimulants',
  OVERDOSE: 'Overdose',
};

const PdmpScoreTile = (props: PdmpProps) => {
  const canvas = useRef<HTMLDivElement>(null);

  const hasData = useMemo(() => {
    if(!props.pdmpPatientResponse || props.error) {
      return false;
    }

    return props.pdmpPatientResponse?.scores?.length > 0;
  }, [props.pdmpPatientResponse]);

  const hasReportUrl = useMemo(() => {
    return props.pdmpPatientResponse?.reportUrl && !Helpers.isEmpty(props.pdmpPatientResponse?.reportUrl);
  }, [props.pdmpPatientResponse]);

  const orsScore = useMemo(() => {
    if(!hasData) return null;
    let score =  props.pdmpPatientResponse?.scores.find(score => score.scoreType === ScoreTypes.OVERDOSE)?.scoreValue;

    if(score) {
      return {
        score: parseInt(score),
        // TODO hardcoded average because that is the color scheme we want for all scores right now
        // when BLUE returns the risk category (below | above | average) we can just change here
        // and automatically the corresponding color will be applied
        category: 'average',
      };
    }

    return null;
  }, [props.pdmpPatientResponse]);

  const narcoticScore = useMemo(() => {
    if(!hasData) return null;
    let score = props.pdmpPatientResponse?.scores.find(score => score.scoreType === ScoreTypes.NARCOTICS)?.scoreValue;
    return score ?? null;
  }, [props.pdmpPatientResponse]);

  const stimulantScore = useMemo(() => {
    if(!hasData) return null;
    let score = props.pdmpPatientResponse?.scores.find(score => score.scoreType === ScoreTypes.STIMULANTS)?.scoreValue;
    return score ?? null;
  }, [props.pdmpPatientResponse]);

  const sedativeScore = useMemo(() => {
    if(!hasData) return null;
    let score = props.pdmpPatientResponse?.scores.find(score => score.scoreType === ScoreTypes.SEDATIVES)?.scoreValue;
    return score ?? null;
  }, [props.pdmpPatientResponse]);

  const ToolTipContent = () => (
    <>
      <StyledTooltipHeader>Unintentional Overdose<br/>Risk Score Model</StyledTooltipHeader>
      <StyledSmallTypography>
        The Unintentional Overdose Risk Score Model (ORS) is a composite index of discrete data obtained
        from the PDMP.
        ORS is comprised of three digits ranging from 000–999 and provides an indicator
        of the likelihood of an unintentional overdose death that medical professionals use to further
        review details in the patient's prescription history while attending to their patients.
        ORS is intended to aid, not replace, medical decision-making. Other patient-centric factors,
        such as mental health conditions, substance use conditions and history of overdose,
        may influence unintentional overdose death but are not incorporated in the
        PDMP data used to calculate ORS.
        None of the information presented should be used as
        sole justification for providing or refusing to provide medications.
      </StyledSmallTypography>
    </>
  );

  useLayoutEffect(() => {
    if(canvas.current) {
      new PdmpScoreGraph(
        canvas.current,
        orsScore?.score ?? 0,
        orsScore?.category ?? ''
      );
    }
  }, [props.pdmpPatientResponse, canvas]);

  if(props.isLoading) return <CSScoreDiv><Loading /></CSScoreDiv>;
  if(!hasData || props.error) return <PdmpError error={props.error} isTile={true} />;

  return (
    <CSScoreDiv>
      <ORSTileHeading>
        Unintentional Overdose Risk Score Model
      </ORSTileHeading>
      <ORSMainDiv>
        <ORSGraph ref={canvas} />
        <ORSScores>
          <ORSScore>
            <ORSLabel>{ScoreTypes.NARCOTICS}</ORSLabel>
            <ORSValue>{narcoticScore}</ORSValue>
          </ORSScore>

          <ORSScore>
            <ORSLabel>{ScoreTypes.STIMULANTS}</ORSLabel>
            <ORSValue>{stimulantScore}</ORSValue>
          </ORSScore>

          <ORSScore>
            <ORSLabel>{ScoreTypes.SEDATIVES}</ORSLabel>
            <ORSValue>{sedativeScore}</ORSValue>
          </ORSScore>
        </ORSScores>
      </ORSMainDiv>
      <ORSDetails>
        <ToolTip html={ToolTipContent()}>
          <IconButton iconClass={ICONS['info-circle']} />
        </ToolTip>

        {
          hasReportUrl &&
          <Button styleType={StyleType.SECONDARY} onClick={() => props.tabsRef.current?.changeTab(props.pdmpTabIndex)}>
            Show full report
          </Button>
        }
      </ORSDetails>
    </CSScoreDiv>
  );
};

export default PdmpScoreTile;
