import { create } from 'zustand';
import { Roster } from '../api/dto/dto';
import {devtools} from "zustand/middleware";

export type RosterState = {
  currentRoster: Roster | null;
  setCurrentRoster: Function;
  selectedRoster: Roster | null;
  setSelectedRoster: Function;
}

export const useRosterStore = create<RosterState>()(
  devtools(
    (set) => ({
      currentRoster: null,
      setCurrentRoster: (val: Roster) => set({currentRoster: val }),
      selectedRoster: null,
      setSelectedRoster: (val: Roster) => set({selectedRoster: val }),
    }),
    { name: 'rosterStore', enabled: ['local', 'dev', 'qa'].includes(process.env.REACT_APP_ENV || 'prod')},
  ),
);
