import axios, {AxiosError, HttpStatusCode} from "axios";
import {PingPatient, UpdatePatientJson} from "../components/pages/clients/manual_encounter/EncounterActions";
import {
  AdmitEncounterJson,
  EncounterClient,
  UpdateAdmitJson,
  UpdateDeceasedJson,
  UpdateDischargeJson,
  UpdatePayorChangeJson,
  UpdateStatusDeceasedJson,
  UpdateStatusDischargeJson,
  UpdateStatusPayorChangeJson,
  UpdateStatusTransferJson,
  UpdateTransferJson
} from "./dto/encounter";
import {ADTItem} from "./dto/client-profile";

axios.interceptors.response.use(function (response) {
  return response;
}, function (error: AxiosError) {
  if (error.response?.status === HttpStatusCode.Unauthorized) {
    window.location.pathname = "/logout";
  } else {
    return Promise.reject(error);
  }
});

export default class EncounterApi {
  getAccessToken: Function;
  baseUrl: string;
  constructor(getAccessToken: Function) {
    this.getAccessToken = getAccessToken;
    this.baseUrl = process.env.REACT_APP_CARECO_API_BASE_URL;
  }

  async updatePatient(patient: UpdatePatientJson, patientId: string, currentRoster: number) {
    let url = `${this.baseUrl}/encounters/roster/${currentRoster}/patient/${patientId}`;

    return axios.put(url,
      patient,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async updateAdmit(update: UpdateAdmitJson, patientId: string, encounterId: string, currentRoster: number) {
    let url = `${this.baseUrl}/encounters`
      + `/roster/${currentRoster}/patient/${patientId}/encounter/${encounterId}/admit`;

    return axios.put(url,
      update,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async updateTransfer(update: UpdateTransferJson,
    patientId: string,
    encounterId: string,
    currentRoster: number,
    eventId: number) {
    let url = `${this.baseUrl}/encounters`
      + `/roster/${currentRoster}/patient/${patientId}/encounter/${encounterId}/transfer/${eventId}`;

    return axios.put(url,
      update,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async updatePayorChange(update: UpdatePayorChangeJson,
    patientId: string,
    encounterId: string,
    currentRoster: number,
    eventId: number) {
    let url = `${this.baseUrl}/encounters`
      + `/roster/${currentRoster}/patient/${patientId}/encounter/${encounterId}/payorchange/${eventId}`;

    return axios.put(url,
      update,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async updateDischarge(update: UpdateDischargeJson, patientId: string, encounterId: string, currentRoster: number) {
    let url = `${this.baseUrl}/encounters` +
      `/roster/${currentRoster}/patient/${patientId}/encounter/${encounterId}/discharge`;

    return axios.put(url,
      update,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async updateDeceased(update: UpdateDeceasedJson, patientId: string, encounterId: string, currentRoster: number) {
    let url = `${this.baseUrl}/encounters` +
      `/roster/${currentRoster}/patient/${patientId}/encounter/${encounterId}/deceased`;

    return axios.put(url,
      update,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async createEncounter(update: AdmitEncounterJson, patientId: string, currentRoster: number) {
    // aka admitting a patient
    let url = `${this.baseUrl}/encounters`
      + `/roster/${currentRoster}/patient/${patientId}/encounter`;

    return axios.post(url,
      update,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async createTransferStatus(update: UpdateStatusTransferJson, patient: PingPatient, currentRoster: number) {
    let url = `${this.baseUrl}/encounters`
      + `/roster/${currentRoster}/patient/${patient.patientId}/encounter/${update.encounterId}/transfer`;

    return axios.post(url,
      update.options,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async createPayorChangeStatus(update: UpdateStatusPayorChangeJson, patient: PingPatient, currentRoster: number) {
    let url = `${this.baseUrl}/encounters`
      + `/roster/${currentRoster}/patient/${patient.patientId}/encounter/${update.encounterId}/payorchange`;

    return axios.post(url,
      update.options,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async createDischargeStatus(update: UpdateStatusDischargeJson, patient: PingPatient, currentRoster: number) {
    let url = `${this.baseUrl}/encounters` +
    `/roster/${currentRoster}/patient/${patient.patientId}/encounter/${update.encounterId}/discharge`;

    return axios.post(url,
      update.options,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async createDeceasedStatus(update: UpdateStatusDeceasedJson, patient: PingPatient, currentRoster: number) {
    let url = `${this.baseUrl}/encounters` +
      `/roster/${currentRoster}/patient/${patient.patientId}/encounter/${update.encounterId}/deceased`;

    return axios.post(url,
      update.options,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async createPatient(rosterId: number, patient: EncounterClient){
    let url = `${this.baseUrl}/encounters/roster/${rosterId}/patient`;

    return axios.post(url,
      patient,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async getEncounters(patientId: string, rosterId: number){
    let url = `${this.baseUrl}/encounters/roster/${rosterId}/patient/${patientId}`;

    let response = await axios.get(url,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );

    const data: ADTItem[] = await response.data;
    return data;
  }

  async deleteDeceased(patientId: string, rosterId: number, encounterId: string){
    let url = `${this.baseUrl}/encounters/roster/${rosterId}/patient/${patientId}` +
      `/encounter/${encounterId}/deceased`;

    await axios.delete(url,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async deleteDischarge(patientId: string, rosterId: number, encounterId: string){
    let url = `${this.baseUrl}/encounters/roster/${rosterId}/patient/${patientId}` +
      `/encounter/${encounterId}/discharge`;

    await axios.delete(url,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async deleteEvent(patientId: string, rosterId: number, encounterId: string, eventId : number){
    let url = `${this.baseUrl}/encounters/roster/${rosterId}/patient/${patientId}` +
      `/encounter/${encounterId}/event/${eventId}`;

    await axios.delete(url,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async deletePayorChange(patientId: string, rosterId: number, encounterId: string, eventId : number){
    let url = `${this.baseUrl}/encounters/roster/${rosterId}/patient/${patientId}` +
        `/encounter/${encounterId}/payorchange/${eventId}`;

    await axios.delete(url,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async deleteTransfer(patientId: string, rosterId: number, encounterId: string, eventId : number){
    let url = `${this.baseUrl}/encounters/roster/${rosterId}/patient/${patientId}` +
        `/encounter/${encounterId}/transfer/${eventId}`;

    await axios.delete(url,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }

  async deleteEncounter(patientId: string, rosterId: number, encounterId: string) {
    // aka deleting admit
    let url = `${this.baseUrl}/encounters/roster/${rosterId}/patient/${patientId}` +
      `/encounter/${encounterId}`;

    return axios.delete(url,
      {
        headers: {
          "Authorization": `Bearer ${await this.getAccessToken()}`
        }
      }
    );
  }
}
